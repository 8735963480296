import apiTool from "./index";
import ApiService from "./ApiService";

const GET_USERS = "api/v1/user/list/";
const ADD_USER = "api/v1/user/create/";
const EDIT_USER = "api/v1/user/<pk>/update/";
const DELETE_USER = "api/v1/user/delete/";
const GET_PERMISSIONS = "api/v1/permissions/get";

const SIGN_UP = "api/v1/user/sign-up/";
const SIGN_IN = "api/v1/user/sign-in/";


export default {
    getUsers(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_USERS, payload).then(response => {
            return response.data;
        });
    },
    addUser(payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_USER, payload).then(response => {
            return response;
        });
    },
    editUser(id, payload) {
        ApiService.setHeader();
        return ApiService.post(EDIT_USER.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    },
    deleteUsers(payload) {
        ApiService.setHeader();
        return ApiService.post(DELETE_USER, payload).then(response => {
            return response;
        });
    },
    getPermissions() {
        ApiService.setHeader();
        return ApiService.get(GET_PERMISSIONS).then(response => {
            return response.data;
        });
    },
    signIn(payload) {
        return ApiService.post(SIGN_IN, payload).then(response => {
            return response.data;
        });
    },
    signUp(payload) {
        return ApiService.post(SIGN_UP, payload).then(response => {
            return response;
        });
    }
};