
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import usersApi from "@/core/services/UsersApi";

export default defineComponent({
    name: "sign-up",
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    data() {
        return {
            token: this.$route.query.token,
        };
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        const submitButton = ref<HTMLElement | null>(null);

        const registration = Yup.object().shape({
            password: Yup.string().min(4).required().label("Password"),
            cpassword: Yup.string()
                .min(4)
                .required()
                .oneOf([Yup.ref("password"), null], "Passwords must match")
                .label("Password Confirmation"),
        });

        const onSubmitRegister = (values) => {
            let payload = {
                password: values.password,
                token: route.params.token,
            };

            // Clear existing errors
            store.dispatch(Actions.LOGOUT);

            // Activate indicator
            submitButton.value?.setAttribute("data-kt-indicator", "on");

            usersApi.signUp(payload).then((res: any) => {
                if (res.data.success) window.location.replace("https://www.chainedesrotisseurs.com/");
                else if (res.data.status === 404) {
                    Swal.fire({
                        title: "Error",
                        text: "You tried to reset your password with an out-of-date link. Please check your mailbox for a more recent one. Or contact the administrator to receive a new one.",
                        icon: "error",
                        confirmButtonText: "Close",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-danger",
                        },
                    });
                    submitButton.value?.setAttribute("data-kt-indicator", "off");
                }
            });
        };

        return {
            registration,
            onSubmitRegister,
            submitButton,
        };
    },
});
